:root {
  --mainColor: 255, 90, 0;
  --mainBg: #f2f2f2;
  --pureWhite: #fff;
  --mainText: #333;
  --maxWidth: 1400px;
}

p {
  color: #333333b0;
  font-size: 1rem;
  line-height: 1.7rem;
  margin-bottom: 1rem;
}

.mainButton {
  background: linear-gradient(
    270deg,
    rgb(243, 107, 34) 0%,
    rgba(255, 54, 0, 1) 100%
  );
  color: var(--mainBg);
  padding: 0.5rem 1rem;
  border-radius: 50px;
  font-weight: 500;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.mainButton:hover {
  animation: buttonAnimation 0.3s ease-in-out forwards;
}

@keyframes buttonAnimation {
  0% {
    background: linear-gradient(
      270deg,
      rgb(243, 107, 34) 0%,
      rgba(255, 54, 0, 1) 100%
    );
  }

  100% {
    background: linear-gradient(
      270deg,
      rgb(245, 21, 60),
      rgba(255, 54, 0, 1) 100%
    );
  }
}

.pageHeader {
  background: url("../images/headerbg.webp");
  height: fit-content;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 2rem;
  margin-bottom: 4rem;
}

.minNav {
  position: absolute;
  top: 0;
  background-color: rgb(var(--mainColor));
  color: var(--mainBg);
  height: 40px;
  padding: 0rem 2rem;
  width: 100%;
  font-weight: 600;
}

.contacts {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.contacts div {
  margin-left: 1rem;
}
.contacts div span:nth-of-type(1) {
  font-size: 1rem;
  margin-left: 0.3rem;
}

.mainNav {
  position: absolute;
  top: 40px;
  display: flex;
  align-items: center;
  padding: 0rem 2rem;
  height: 95px;
  width: 100%;
  max-width: var(--maxWidth);
  margin-left: auto;
  margin-right: auto;
  transition: max-width 0.2s ease-in-out;
}

.fixedMenu {
  position: fixed;
  max-width: 100%;
  background-color: var(--pureWhite);
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.06);
}

.logo {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 900;
  text-decoration: none;
  color: #333;
}
.logo:hover {
  text-decoration: none;
  color: #333;
}
.logo img {
  width: 55px;
  height: 55px;
  margin-right: 0.5rem;
}
/* .openMenu {
  display: none;
} */

.NavLinks {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}
.NavLinks li {
  margin: 0 0.5rem;
}
.NavLinks li a {
  position: relative;
  padding: 0.5rem 0.2rem;
  text-decoration: none;
  color: var(--mainText);
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  transition: Color 0.2s ease-in-out;
}
.NavLinks li a::after {
  position: absolute;
  top: 100%;
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: rgb(var(--mainColor));
  transition: width 0.3s;
}
.NavLinks li a:hover {
  color: rgb(var(--mainColor));
}
.NavLinks li a:hover::after {
  width: 100%;
}

.NavLinks li a.activeLink {
  color: var(--pureWhite);
  background-color: rgb(var(--mainColor));
}
.NavLinks li a.activeLink:hover {
  color: var(--mainBg);
}

.donate {
  margin-left: auto;
}

@media (max-width: 1199.98px) {
  .mainNav ul {
    padding-left: 1rem;
  }
  .mainNav ul li {
    margin-left: 0rem;
  }
}

@media (max-width: 991.98px) {
  .mainButton {
    font-size: 0.9rem;
  }
  .mainNav {
    background-color: var(--pureWhite);
    height: 70px;
    padding: 0rem 1rem;
    z-index: 100;
  }
  .mainNav .logo img {
    height: 50px;
    width: 50px;
  }
  .openMenu {
    display: block;
    cursor: pointer;
    background-color: var(--mainBg);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    transition: background-color 0.2s ease-in-out;
  }
  .openMenu:hover {
    background-color: rgb(var(--mainColor));
  }
  .openMenu:hover span {
    background-color: var(--mainBg);
    transition: all 0.2s ease-in-out;
  }
  .openMenu span {
    display: block;
    width: 23px;
    height: 2px;
    background-color: rgb(var(--mainColor));
    margin: 3px;
    border-radius: 10px;
  }
  .anima span:nth-of-type(1) {
    transform: rotate(45deg) translate(4px, 2px);
  }
  .anima span:nth-of-type(2) {
    display: none;
  }
  .anima span:nth-of-type(3) {
    transform: rotate(-45deg) translate(4px, -2px);
  }
  .donate {
    margin-left: 1rem;
  }
  .NavLinks {
    position: absolute;
    top: 100%;
    flex-direction: column;
    background-color: var(--pureWhite);
    width: 100%;
    left: -100%;
    padding-bottom: 2rem;
    transition: left 0.2s ease-in-out;
  }
  .showNavLinks {
    left: 0;
  }
  .NavLinks:before {
    content: "";
    display: block;
    width: 95%;
    height: 2px;
    background-color: var(--mainBg);
    position: absolute;
    top: 0;
    left: 2.5%;
    z-index: 1;
  }
  .NavLinks li {
    width: 100%;
    text-align: start;
    padding: 1rem 0rem;
    /* border-bottom: 1px solid #e5e5e5; */
  }

  .footerSections {
    grid-template-columns: repeat(2, 1fr);
  }
  .footerSection ul li {
    font-size: 0.9rem;
  }
  .footerSection ul li a {
    margin-bottom: 0.5rem;
  }
  .footerCopright {
    flex-direction: column;
  }
  .footerCopright p {
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  footer {
    padding: 2rem 0rem;
  }
  .footerLogo {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .minNav span:nth-of-type(1) {
    font-size: 0.7rem !important;
  }
  .mainNav .logo {
    font-size: 0.8rem;
  }
  .mainNav .logo img {
    max-width: 40px;
    max-height: 40px;
  }
  .footerContent {
    padding: 1rem 0rem;
  }
  .footerSections {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 398px) {
  .mainNav .logo span {
    display: none;
  }
}
