:root {
  --mainbg: rgb(28, 26, 26);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgb(245, 21, 60);
  --mainColorLight: rgba(255, 54, 0, 1);
  --subColorLight: brown;
  --white: #fff;
}

/* --pale: #cecece;
  --dark: #010101; */

.main {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: var(--dark);
  line-height: 1.5;
}

.minNav {
  background-color: var(--mainColor);
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0rem 2rem;
}

#nav2 {
  display: none;
}

.minNav a {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  color: var(--white);
  opacity: 0.5;
  font-size: 1.2rem;
  transition: 0.25s;
}

.minNav a:hover {
  opacity: 0.8;
}
