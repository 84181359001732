:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
}

.donut {
  width: 3rem;
  height: 3rem;
  margin: 2rem auto;
  border-radius: 50%;
  border: 0.3rem solid var(--subColorLight);
  border-top-color: var(--mainColor);
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.69, 0.12, 0.83, 0.67);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
