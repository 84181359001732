:root {
  --mainbg: #333;
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgb(245, 21, 60);
  --mainColorLight: rgba(255, 54, 0, 1);
  --subColorLight: brown;
  --white: #fff;
}
.childrenWrapper {
  background-color: var(--mainbg);
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.childCardHolder {
  padding-top: 7rem;
  padding-bottom: 1.5rem;
}

.addChildrenContainer {
  padding: 2rem;
  padding-top: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addChildrenButton {
  background-color: var(--mainColor);
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.375rem 1.5rem;
  border-radius: 50rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: var(--white);
  display: block;
  margin-bottom: 1rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
}
.addChildrenButton:hover {
  background-color: var(--subColor);
  color: var(--white);
}
