:root {
  --mainbg: #333;
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgb(245, 21, 60);
  --mainColorLight: rgba(255, 54, 0, 1);
  --subColorLight: brown;
  --white: #fff;
}

.childrenPageHeader {
  padding-top: 5rem;
  padding-bottom: 7rem;
}

.headerImageWrapper {
  padding: 1rem;
  position: relative;
}

.headerImageWrapper img {
  width: 100%;
}

.headerImageWrapper:after {
  position: absolute;
  content: "";
  height: 60%;
  width: 60%;
  background-color: var(--mainbg);
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 0px 0px 10px 0px;
}
.headerImageWrapper:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 60%;
  background-color: var(--mainColor);
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 10px 0px 0px 10px;
}

.headerText {
  height: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 2rem;
}

@media (max-width: 991.98px) {
  .childrenPageHeader {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .headerImageWrapper {
    margin-bottom: 2rem;
  }
}
