:root {
  --mainbg: #333;
  --mainColor: rgb(255, 90, 0);
  --subColor: #25aae2;
  --mainbgLight: rgb(245, 21, 60);
  --mainColorLight: rgba(255, 54, 0, 1);
  --subColorLight: brown;
  --white: #fff;
}

.childCard {
  border-radius: 0px;
  margin-top: 0rem;
  margin-bottom: 0rem;
  text-align: center;
  padding-bottom: 0rem;
  height: 100%;
}
.childCardSponsored {
  filter: grayscale(1);
  transition: 0.3s;
  cursor: pointer;
}
.childCardSponsored:hover {
  filter: grayscale(0);
}

.childCardImage {
  border-radius: 50%;
  margin-top: -100px;
  max-width: 80%;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.childCardTitle {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  line-height: 1.2;
}

.childCardStory {
  display: block;
  background-color: transparent;
  color: inherit;
  border: none;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}
.childCardStory:hover {
  background-color: transparent;
  text-decoration: underline;
  color: var(--mainbg);
}

.storyIcon {
  color: var(--mainbg);
  padding-right: 0.5rem;
}

.childCardButton,
.editAChildButton,
.deleteAChildButton {
  background-color: rgb(255, 90, 0);
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.375rem 1.5rem;
  border-radius: 50rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: var(--white);
  display: block;
  margin-bottom: 1rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
}
.childCardButton:hover {
  background-color: var(--mainbg);
  color: var(--white);
}

.editAChildButton {
  background-color: var(--mainbgLight);
}

.sponsorStatus {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sponsorStatus input {
  margin-right: 0.5rem;
  accent-color: var(--mainbg);
}

.editAChildButton:hover {
  background-color: var(--mainbg);
  color: var(--white);
}
.deleteAChildButton {
  background-color: var(--subColorLight);
}
.deleteAChildButton:hover {
  background-color: var(--subColor);
  color: var(--white);
}
