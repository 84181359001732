.overlay {
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.48);
  position: fixed;
  overflow-y: scroll;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}
