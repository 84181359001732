:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
  --palebg: #f3f0e9;
}

.loginContainer {
  margin-top: 0px;
  background-color: var(--palebg);
  box-sizing: border-box;
  padding-top: 5rem;
  padding-bottom: 5rem;
  min-height: calc(100vh - 142px);
}
.innerContent {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem;
  border-radius: 5px;
  background-color: var(--white);
  color: var(--mainbg);
  position: relative;
}

.innerContent:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    #e23505 4%,
    #db4b04 13.2857%,
    #c87005 29%,
    #db7f06 11.5714%,
    #cbbe06 36%,
    #a8d064 50.8571%,
    #a8d064 64%,
    #3cab1a 57.1428%,
    #3cab1a 67%,
    #008c15 71.4285%,
    #008c15 0,
    #00630f 85.7142%,
    #00630f 0
  );
}

h4 {
  text-align: center;
  font-weight: bold;
}

.formInputs {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

input {
  padding-left: 5px;
  height: 40px;
}

.loginButton,
.allChildren {
  padding: 0.8rem 2rem;
  background-color: #ca830e;
  color: #fff;
  border: 2px solid transparent;
  box-shadow: -5px -5px 15px rgb(255 255 255 / 90%),
    5px 5px 15px rgb(107 69 7 / 30%);
  border-radius: 50rem;
  border: none;
  transition: 0.3s;
  text-decoration: none;
  font-weight: bold;
  width: fit-content;
}

.simpleLinks {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.allChildren {
  background-color: var(--mainbgLight);
}

.loginButton:hover {
  background-color: var(--mainbg);
}

.allChildren:hover {
  background-color: var(--mainbg);
  color: var(--white);
}

.connectLink {
  color: var(--mainColor);
  transition: 0.3s;
}

.connectLink:hover {
  color: var(--mainColorLight);
}

@media (max-width: 767.98px) {
  .childCardImage {
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 575.98px) {
  .innerContent {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
